import { useState } from 'react';
import { FaCity, FaPhone, FaEnvelope } from 'react-icons/fa6';
import emailjs from '@emailjs/browser';

emailjs.init({
  publicKey: "T2RGqpA-xW0L0Wp26",
});

const sendEmail = async (event) => {
    event.preventDefault();
    
    const name = event.target.elements.name.value;
    const company = event.target.elements.company.value;
    const email = event.target.elements.email.value;
    const phone = event.target.elements.phone.value;
    const message = event.target.elements.message.value;

    const result = await emailjs.send('service_ou9o736', 'template_g360m6t', {
      'from_name': email,
      'to_name': 'Double XP Software',
      'message': JSON.stringify({ name, email, company, phone, message})
    });

    return result;

}

const ContactForm = () => {
  const [emailState, setEmailState] = useState(null);

    return (
        <div class="">

          <div class="grid grid-cols-1 col-auto gap-x-20  py-8 lg:grid-cols-2">
              <div>
                <div class="mt-6 text-4xl font-bold leading-8 ">
                  <a id="contact">Contact Us</a>
                </div>
                <div>
                  <div class="py-8">
                    Whether you're brainstorming a new concept and unsure where to begin, or you're looking to enhance existing software with more features, or just need technical guidance, we're eager to connect and explore ways we can assist you.                </div>
                  </div>
                  <div>
                  <div class="flex flex-row pb-6">
                    <FaCity size={24} />
                    <div class="px-6">
                      <div>9817 S 47th Pl</div>
                      <div>Phoenix, AZ 85044</div>
                    </div>
                  </div>
                  <div class="flex flex-row pb-6">
                    <FaPhone size={24} />
                    <div class="px-6">
                      <a href="tel:+14805257229">+1 (480) 525-7229</a>
                    </div>
                  </div>

                  <div class="flex flex-row">
                    <FaEnvelope size={24} />
                    <div class="px-6">
                      <a href="mailto:contact@2xpsoftware.com">contact@2xpsoftware.com</a>
                    </div>
                  </div>
                  
                </div>
                </div>
              <div>
              <form onSubmit={async (e) => {
                setEmailState('Sending...');
                try {
                  await sendEmail(e);
                  setEmailState('Message sent!');
                } catch (e) {
                  setEmailState('Error sending message, try emailing us');
                }
              }}
                 class="mx-auto mt-16 max-w-xl sm:mt-20">
                  <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div class="sm:col-span-2">
                      <label for="name" class="block text-sm font-semibold leading-6 ">Your Name</label>
                      <div class="mt-2.5">
                        <input type="text" name="name" id="name" autocomplete="given-name" class="text-black block w-full rounded-md border-0 px-3.5 py-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"/>
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <label for="company" class="block text-sm font-semibold leading-6 ">Company</label>
                      <div class="mt-2.5">
                        <input type="text" name="company" id="company" autocomplete="organization" class="text-black block w-full rounded-md border-0 px-3.5 py-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"/>
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <label for="email" class="block text-sm font-semibold leading-6 ">Email</label>
                      <div class="mt-2.5">
                        <input type="email" name="email" id="email" autocomplete="email" class="text-black block w-full rounded-md border-0 px-3.5 py-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"/>
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <label for="phone" class="block text-sm font-semibold leading-6 ">Phone number</label>
                      <div class="relative mt-2.5">
                        <input type="tel" name="phone" id="phone" autocomplete="tel" class="text-black block w-full rounded-md border-0 px-3.5 py-2  shadow-sm ring-1 ring-inset ring-gray-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"/>
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <label for="message" class="block text-sm font-semibold leading-6">Message</label>
                      <div class="mt-2.5">
                        <textarea name="message" id="message" rows="4" class="text-black block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder: focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"></textarea>
                      </div>
                    </div>
                    
                  </div>
                  <div class="mt-10">
                    <button type="submit" class="block w-full rounded-md bg-gray-700 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                      {emailState == null ? 'Send message' : emailState}
                    </button>
                  </div>
                </form>
              </div>
          </div>
        </div>
    );
}

export default ContactForm;