import React, { useState, useRef, useEffect} from 'react';

const WindowScrollView = ({ render, offset = 0 }) => {
    const viewRef = useRef(null);
    const [location, setLocation] = useState(0);
  
  useEffect(() => {
    const eventListener = window.addEventListener('scroll', () => {
      if (viewRef != null && viewRef.current != null) {
        const boundingRect = viewRef.current.getBoundingClientRect();
        setLocation(Math.min(1, Math.max(0, (window.innerHeight - boundingRect.y + (offset * window.innerHeight))/window.innerHeight)));
      }
    });

    return () => {
      window.removeEventListener('scroll', eventListener);
    }
  }, [viewRef, setLocation]);

    return (
        <span ref={viewRef}>
            {render(location)}
        </span>
    );
}

export default WindowScrollView;