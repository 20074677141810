import logo from '../images/2xpsoft.png';

const NavBar = () => {
    return (
        <div class="mx-auto max-w-7xl">
            <div class="flex h-24 items-center justify-between lg:h-32">
            <div class="flex items-center">
                <div class="flex-shrink-0 pr-4">
                <img src={logo} class="h-11" />
                </div>
                

            </div>

            <div class="hidden md:block ">
                <div class="ml-10 flex items-baseline space-x-4">
                    <a href="#services" class="hover:underline hover:decoration-2 hover:underline-offset-8 px-3 py-2 text-sm font-medium">Our Services</a>
                    <a href="#work" class="hover:underline hover:decoration-2 hover:underline-offset-8 px-3 py-2 text-sm font-medium">Our Work</a>
                    <a href="#contact" class="hover:underline hover:decoration-2 hover:underline-offset-8 px-3 py-2 text-sm font-medium">Contact Us</a>
                    <a href="#about" class="hover:underline hover:decoration-2 hover:underline-offset-8 px-3 py-2 text-sm font-medium">About Us</a>
                </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar;