import { FaLinkedin, FaGithub } from 'react-icons/fa6';

import { easeQuadOut } from 'd3-ease';

import dicematesScreen from './images/dicemates-screen.png';
import dicematesMobile from './images/dicemates-mobile.png';
import ProfilePic from './images/profilepic.jpeg';
import backgroundVideo from './background-video.mp4';

import PrototypeIcon from './images/3d-modeling.png';
import MobileIcon from './images/smartphone.png';
import WebIcon from './images/006-web-dev.png';
import ApiIcon from './images/004-api.png';
import ServiceIcon from './images/001-question.png';
import SecurityIcon from './images/005-shield.png';

import './App.css';
import WindowScrollView from './util/WindowScrollView';
import ServiceBlock from './components/ServiceBlock';
import EmphasisText from './components/EmphasisText';
import NavBar from './components/NavBar';
import ContactForm from './components/ContactForm';

function App() {

  return (
    <div class="text-gray-300">
      <div class="absolute -z-50">
        <div class="relative overflow-hidden">
          <video autoPlay loop muted playsInline style={{ minHeight: '100vh', width: '100%', objectFit: 'cover' }}>
            <source src={backgroundVideo} type='video/mp4' />
          </video>
          <div className='video-gradient' />
        </div>
      </div>

    <div class="px-9 lg:px-11">

      <NavBar />

      <div class="mx-auto max-w-7xl">
        <div class="py-24 sm:py-48 lg:py-48 lg:mx-w-4xl">
          <div class="text-left">
            <h1 class="text-5xl font-bold tracking-tight lg:text-6xl">Turn your <span className="cursive">Vision</span> into  <span className="superbold-background"><span className="superbold">Reality</span></span>
            <br/>
            with Double XP Software.
            </h1>
            <p class="mt-6 max-w-2xl text-lg leading-8 ">We specialize in helping founders and small businesses turn their innovative ideas into tangible products, offering comprehensive support to build robust software solutions and providing expert technical guidance to navigate the complexities of the digital landscape.</p>
          </div>
        </div>
      </div>

      <div class="mx-auto max-w-7xl">
        <div class="">
          <div class="text-left">
            <a id="services"><p class="mt-6 text-4xl font-bold leading-8 ">Our Services</p></a>
          </div>

          <div class="grid grid-cols-1 col-auto gap-x-20 gap-y-10 py-8 lg:grid-cols-2">

            <ServiceBlock
              title="Rapid Software Prototyping"
              icon={PrototypeIcon}
              description={
                <>We specialize in quickly turning concepts into working applications allowing you to <EmphasisText>validate your ideas</EmphasisText>, gather <EmphasisText>user feedback</EmphasisText>, and make informed decisions early in the development process. We will work closely with you to ensure <EmphasisText>fast delivery</EmphasisText>, <EmphasisText>flexibility</EmphasisText>, and the ability to adapt to changing requirements.</>
              }
            />

            <ServiceBlock
              title="Mobile App Development"
              icon={MobileIcon}
              description={
                <>Whether you're targeting <EmphasisText>iOS</EmphasisText>, <EmphasisText>Android</EmphasisText>, or both, our team has the expertise to create engaging, user-friendly, and high-performance mobile applications that meet your business needs and exceed your expectations.</>
              }
            />

            <ServiceBlock
              title="Web Application Development"
              icon={WebIcon}
              description={
                <>Our web app development services cover everything from <EmphasisText>Single Page Applications (SPAs)</EmphasisText> to complex <EmphasisText>Multi-Tenant Dashboards</EmphasisText> and <EmphasisText>Content Management Systems (CMSs)</EmphasisText>. We focus on creating responsive, scalable, and secure web applications that work effortlessly across all devices and platforms.</>
              }
            />

            <ServiceBlock
              title="API Development and Integrations"
              icon={ApiIcon}
              description={
                <>Harness the power of connectivity and interoperability across various systems and applications. Our expertise spans from crafting <EmphasisText>RESTful APIs</EmphasisText> to integrating complex <EmphasisText>Third-Party APIs</EmphasisText> and developing robust <EmphasisText>Cloud Based Microservice Architectures</EmphasisText>. We prioritize building scalable, efficient, and secure solutions that enable your systems to scale with your business needs.</>
              }
            />

            <ServiceBlock
              title="Technical Advising"
              icon={ServiceIcon}
              description={
                <>Navigate the complexities of technology with our expert technical advising services. We provide strategic guidance on <EmphasisText>technology selection</EmphasisText>, <EmphasisText>system architecture</EmphasisText>, <EmphasisText>best practices</EmphasisText>, <EmphasisText>hiring</EmphasisText>, and more, helping you make informed decisions that align with your business goals and technology needs.</>
              }
            />

            <ServiceBlock
              title="Compliance Review"
              icon={SecurityIcon}
              description={
                <>In today's digital world, ensuring your business adheres to industry regulations and standards is more crucial than ever. We have experience building software with <EmphasisText>Health Insurance Portability and Accountability Act (HIPPA)</EmphasisText>, <EmphasisText>Payment Card Industry Data Security Standard (PCI DSS)</EmphasisText>, and <EmphasisText>Personally Identifiable Information (PII)</EmphasisText> regulation compliance.</>
              }
            />

          </div>
        </div>
      </div>

      <div class="mx-auto max-w-7xl py-32">
        <div class="">
          <div class="text-left">
          <a id="work"><p class="mt-6 text-4xl font-bold leading-8 ">Our Work</p></a>
          </div>

          <div class="grid grid-cols-1 col-auto gap-x-20 lg:grid-cols-3" >
            <div class="my-16 relative lg:col-span-2" >
              <img class="rounded-md" src={dicematesScreen} />
              <WindowScrollView
                offset={0.3}
                render={(location) => (
                  <img class='absolute w-1/4 -bottom-10' style={{ left: `${easeQuadOut(location)*100}%`, transform: 'translateX(-200%)' }} src={dicematesMobile} />
                )}
              />
            </div>

            <div class=" ">
              <div class="text-3xl pt-6 pb-8 hover:underline hover:decoration-2 hover:underline-offset-8 lg:pt-16">
                <a href="https://dicemates.com" target="_blank">Dicemates.com</a>
              </div>
              <div>
                Peer to Peer marketplace allowing users to buy and sell board games. Utilizing Stripe, Google Analytics, HubSpot, and Funnel to facilite bank transactions and customer acquisition pipelines.
              </div>
            </div>

          </div>

        </div>
      </div>


      <div class="mx-auto max-w-7xl">
        <ContactForm />
      </div>

      <div class="mx-auto max-w-7xl px-4 py-32">
        <div class="pb-20">
          <div class="text-left">
            <a id="about"><p class="mt-6 text-4xl font-bold leading-8 ">About Us</p></a>
          </div>

        </div>

        <div class="grid grid-cols-1">
            <img class="rounded-full h-48 w-48 mx-auto grayscale" src={ProfilePic}/>
            <div class="mx-auto pt-8 text-xl">Casey Tickes</div>
            <div class="mx-auto text-sm">Founder & Principal Consultant</div>
            <div class="flex flex-row justify-center py-4">
                <a class="px-2" href="https://linkedin.com/in/caseytickes" target="_blank"><FaLinkedin size={24}/></a>
                <a class="px-2" href="https://github.com/caseyt" target="_blank"><FaGithub size={24}/></a>
            </div>
            <div class="mx-auto max-w-3xl">
              Casey has been working in software develpment for over 15 years and has worked on everything from Apple App Store Top 10 mobile applications, to ticketing and access control platforms used by the country's largest events, to IoT applications handling millions of messages per hour.
              Casey draws on his extensive experience and industry connections to take ideas or problems, analyze possible solutions, come up with a plan, and execute on that plan to produce the highest quality applications and software platforms that are guaranteed to fit your needs.
            </div>
        </div>
      </div>

      <div class="mx-auto pt-16 pb-8 text-center">
        © Double XP Software 2024
      </div>


    </div>
    
    </div>
  );
}

export default App;
