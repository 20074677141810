import WindowScrollView from "../util/WindowScrollView";


const EmphasisText = ({ children }) => {

    return (
        <WindowScrollView
            render={location => {
                return (
                    <span 
                        className='emphasis'
                        style={{ 
                            backgroundImage: `linear-gradient(transparent, transparent), linear-gradient(transparent, transparent), linear-gradient(to right, transparent, rgb(209, 213, 219) ${location*100}%, transparent)`
                        }}>
                        {children}
                    </span>
                )
            }}
        />
    );
}

export default EmphasisText;