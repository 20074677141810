
const ServiceBlock = ({title, description, icon}) => {

    return (
        <div class="grid grid-cols-5 gap-12">
            <div class="py-4 hidden lg:visible">
                <img src={icon} />
            </div>

            <div class="col-span-5 lg:col-span-4">

                <div class="text-3xl py-4">
                    {title}
                </div>
            
                <div>
                    {description}
                </div>

            </div>
        </div>
    )
};

export default ServiceBlock;